.loginb {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .admin{
    padding: 30px;
  
  }
  .login__containerb {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
  }
  .login__textBoxb {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 15px;

  }
  .login__btnb {
    /* padding: 20px; */
    font-size: 18px;
    margin-top: 30px;
    border: none;
    color: white;
    font-weight: bolder;
    background-color: rgb(255, 121, 121);
  }
  .login__googleb {
    background-color: #4285f4;
  }
  .loginb {
    margin-top: 7px;
  }